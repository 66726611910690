<template>
    <div id="navbar-component">
        <div v-if="$auth.user()">
            <v-app-bar v-if="is_mobile && $store.getters['manage_navbar/show_left_navbar']"
                       app color="white" height="90" class="app-bar" data-cy="mobile-appbar"
            >
                <v-layout class="justify-start">
                    <v-icon @click="drawer = !drawer" class="my-auto round-hitbox-icon" data-cy="menu-burger">mdi-menu</v-icon>

                    <v-btn v-if="$store.getters['notifications/total_notifications_not_see'] > 0"
                           class="unsolicited-btn notification-pin-menu-leftnavbar"
                           elevation="0" color="error" fab
                    />

                    <img alt="img_logo" :src="$store.getters['display_option/logo']" height="40px" class="mx-2"/>
                </v-layout>

                <v-layout class="justify-end">
                    <div class="nav-grid">
                        <div>
                            <v-icon class="font-color-normal pointer d-block" size="30"
                                    @click="$refs.DialogContactComponent.dialog_contact = true"
                                    v-if="$store.getters['user_connected/user_connected'].id_professional === 14"
                            >
                                mdi-lifebuoy
                            </v-icon>
                        </div>
                        <div class="size-30" @click="navigateTo('notifications')">
                            <v-badge :value="$store.getters['notifications/total_notifications_not_see']"
                                     :content="$store.getters['notifications/total_notifications_not_see']"
                                     class="badge-notification-lower-size" color="error" overlap
                            >
                                <v-icon class="mx-auto font-color-normal" size="30"
                                        @click="navigateTo('notifications')"
                                >
                                    mdi-bell
                                </v-icon>
                            </v-badge>
                        </div>
                        <div class="pointer" @click="$refs.dialogProfileComponent.dialog_profile = true">
                            <profile-picture-component/>
                        </div>

                        <span>
                           <span class="font-10 pointer" @click="$refs.DialogContactComponent.dialog_contact = true"
                                 v-if="$store.getters['user_connected/user_connected'].id_professional === 14"
                           >
                                {{ $t('left_navbar_component.help') }}
                            </span>
                        </span>

                        <span class="font-10 pointer" @click="navigateTo('notifications')">
                            {{ $t('left_navbar_component.notifications') }}
                        </span>

                        <span class="font-10 pointer" @click="$refs.dialogProfileComponent.dialog_profile = true">
                            {{ $t('left_navbar_component.profile') }}
                        </span>
                    </div>
                </v-layout>
            </v-app-bar>

            <v-navigation-drawer v-model="drawer" :temporary="is_mobile" app mobile-breakpoint="" color="white" data-cy="left-navbar">
                <div class="pt-12 pl-4 d-flex justify-start">
                    <img :src="$store.getters['display_option/logo']" style="max-width: 50%; max-height: 64px"
                         class="mr-2" alt="Logo"
                    >
                    <span class="secondary--text font-14 my-auto mr-2 line-height-16">
                        {{ $store.getters["user_connected/user_connected"].client_space_name }}
                    </span>
                </div>

                <v-list>
                    <v-list-item-group color="primary">

                        <v-divider class="ml-3 mr-5" color="white"/>
                        <v-progress-linear indeterminate v-if="$store.state.manage_navbar.loading"/>

                        <template
                            v-for="configuration in $store.getters['user_navbar_configuration/navbar_configurations']"
                        >
                            <v-list-item
                                v-if="configuration.displayed && userHasAccess(configuration.designation, role_no_access)"
                                :key="configuration.page_link" :to="{ path: '/' + configuration.page_link }"
                                class="py-1" link
                                :data-cy="'navbar-item-' + configuration.page_link"
                            >
                                <v-list-item-icon class="mr-6 ml-2">
                                    <v-icon>{{ configuration.icon }}</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-badge :value="calculateNbNotificationsByModule(configuration.page_link)"
                                                 :content="calculateNbNotificationsByModule(configuration.page_link)"
                                                 class="badge-notification-lower-size my-auto" color="error" inline
                                        >
                                            <span v-if="configuration.page_link === 'equipment'">
                                                {{ page_name_equipment }}
                                            </span>
                                            <span v-else>
                                                {{ $t('configuration_navbar.' + configuration.page_name) }}
                                            </span>
                                        </v-badge>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>

                        <v-divider class="ml-3 mr-5" color="white"/>

                        <v-list-item class="py-1" link :to="{ path: '/parameter'}" :data-cy="'navbar-item-parameter'">
                            <v-list-item-icon class="mr-6 ml-2">
                                <v-icon>mdi-cog</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    <span>{{ $t('left_navbar_component.parameters') }}</span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider class="mb-2 ml-3 mr-5" color="white"/>

                    </v-list-item-group>
                </v-list>
            </v-navigation-drawer>
        </div>

        <dialog-profile-component ref="dialogProfileComponent"/>

        <dialog-contact-component ref="DialogContactComponent"/>
    </div>
</template>

<script>
import DialogProfileComponent from "@/components/Profile/DialogProfileComponent";
import DialogContactComponent from "@/components/Global/DialogContactComponent.vue";
import ProfilePictureComponent from "@/components/Profile/ProfilePictureComponent";

export default {
    name: "NavbarLeftComponent",
    components: {
        ProfilePictureComponent,
        DialogProfileComponent,
        DialogContactComponent
    },

    methods: {
        redirection(route) {
            if (route === this.$router.currentRoute.name && this.is_mobile) {
                this.drawer = false;
            }
            this.navigateTo(route)
        }
    }
}
</script>

<style>
.logo-left-navbar .v-image__image {
    background-position: left !important;
}

.notification-pin-menu-leftnavbar {
    height: 7px !important;
    width: 7px !important;
    position: absolute !important;
    top: 35px;
    left: 33px;
}

.app-bar {
    box-shadow: 0px 4px 26px #3C3C4449 !important;
}

.round-hitbox-icon {
    height: 27px;
}
</style>