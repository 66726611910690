import UserConfigurationRepository from "@/repositories/UserConfigurationRepository";

const state = () => ({
    page_order_configuration_equipment: null,
});

const mutations = {
    setPageOrderConfigurationEquipment(state, pageOrderConfigurationEquipment: IPageOrderConfiguration): void {
        state.page_order_configuration_equipment = pageOrderConfigurationEquipment;
    },


    openOrCloseWidgetEquipment(state, payload): void {
        state.page_order_configuration_equipment.configuration.filter((configuration) => {
            if (configuration.widget_name === payload.component_title) {
                configuration.is_open = !configuration.is_open;
            }
        });

        if (payload.id_page_order) {
            UserConfigurationRepository.updatePageOrderConfiguration('equipment', state.page_order_configuration_equipment.configuration);
        } else {
            UserConfigurationRepository.postPageOrderConfiguration(
                'equipment', state.page_order_configuration_equipment.configuration
            ).then((success) => {
                state.page_order_configuration_equipment = success.data.data;
            });
        }
    }
};

const actions = {
    async getPageOrderConfigurationEquipment({commit}): Promise<void> {
        await UserConfigurationRepository.getPageOrderConfigurationByConnectedUSer('equipment').then((success) => {
            commit('setPageOrderConfigurationEquipment', success.data.data);
        });
    },

    async createPageOrderConfigurationEquipment({commit}, payload): Promise<void> {
        await UserConfigurationRepository.postPageOrderConfiguration(payload.page, payload.order_configuration).then((success) => {
            commit('setPageOrderConfigurationEquipment', success.data.data);
        });
    },

    async updatePageOrderConfigurationEquipment({commit}, payload): Promise<void> {
        await UserConfigurationRepository.updatePageOrderConfiguration(payload.page, payload.order_configuration).then((success) => {
            commit('setPageOrderConfigurationEquipment', success.data.data);
        });
    },

    async openOrCloseWidgetEquipment({commit}, payload): Promise<void> {
        commit('openOrCloseWidgetEquipment', {
            component_title: payload.component_title,
            id_page_order: payload.id_page_order
        });
    }
};

const getters = {
    page_order_configuration_equipment: (state) => state.page_order_configuration_equipment,
    order_configuration_equipment: (state) => state.page_order_configuration_equipment ? state.page_order_configuration_equipment.configuration : null
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
