import Vue from 'vue';
import VueInternationalization, {LocaleMessages} from 'vue-i18n';

Vue.use(VueInternationalization);

function loadLocaleMessages(): LocaleMessages {
    const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages: LocaleMessages = {};

    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);

        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });

    return messages;
}

const options = {
    locale: navigator.language,
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'fr-FR',
    messages: loadLocaleMessages()
};

export default new VueInternationalization(options);
