<template>
    <div id="app">
        <HiddenInputNotifications />
        <v-app class="global" v-if="$store.getters['general/come_from_sens'] || (!$store.getters['general/come_from_sens'] && !$store.getters['general/show_global_loader'])">

            <left-navbar-component/>

            <v-main :class="$auth.user() ? 'background-color-main' : ''">
                <router-view/>
            </v-main>

            <bottom-navbar-component/>

            <snackbar-component/>
        </v-app>

        <div class="global-loader" v-if="$store.getters['general/show_global_loader']">
            <div class="loader"></div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import './scss/app.scss';
import LeftNavbarComponent from "@/components/Navigation/LeftNavbarComponent.vue";
import BottomNavbarComponent from "@/components/Navigation/BottomNavbarComponent.vue";
import SnackbarComponent from "@/components/Global/SnackbarComponent.vue";
import HiddenInputNotifications from "@/components/HiddenInput/Notifications.vue";

export default Vue.extend({
    name: 'App',
    components: {SnackbarComponent, BottomNavbarComponent, LeftNavbarComponent, HiddenInputNotifications },
    data() {
        return {
            registration: null,
        }
    },
    created() {
        this.$store.dispatch('general/setGlobalLoader', true);
        this.$auth.load().then(() => {
            //Check if application is install or on browser
            if (!this.$auth.user() && !window.matchMedia('(display-mode: standalone)').matches) {
                this.getDisplayOptionByPwaSensUrl();
            } else {
                this.setDisplayOption();
                this.$store.dispatch('general/setGlobalLoader', false);
            }
        });

        document.addEventListener('serviceWorkerUpdateEvent', this.updateAvailable, {once: true})
        navigator.serviceWorker.addEventListener('controllerchange', () => {
            window.location.reload();
        });
    },
    methods: {
        updateAvailable(event) {
            this.registration = event.detail;
            if (this.registration || this.registration.waiting) {
                this.registration.waiting.postMessage({type: 'SKIP_WAITING'});
            }
        },

        getDisplayOptionByPwaSensUrl() {
            this.$store.dispatch(
                'display_option/getDisplayOptionByPwaSensUrl'
            ).then(() => {
                this.setDisplayOption();
            }).catch((error) => {
                if (error.response && error.response.status === 404) {
                    this.$store.dispatch('snackbar/showSnackbar', {
                        snackbar_type: 'warning',
                        snackbar_text: this.$t(`error_display.${error.response.data.translation_key}`)
                    });
                } else {
                    this.$store.dispatch('snackbar/showSnackbar', {
                        snackbar_type: 'error', snackbar_text: this.$t('error_display.display_option_error')
                    });
                }
            }).finally(() => {
                this.$store.dispatch('general/setGlobalLoader', false);
            });
        }
    }
});
</script>

<style>
@font-face {
    font-family: "Sarabun";
    src: local("Sarabun"),
    url(/fonts/Sarabun/Sarabun-Regular.ttf) format("truetype");
}

.global-loader {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #ffffff;
    top: 0;
    left: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader {
    display: inline-block;
    width: 64px;
    height: 64px;
}

.loader:after {
    content: " ";
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px black solid;
    border-color: black transparent;
    -webkit-animation: lds-dual-ring 1.2s linear infinite;
    animation: lds-dual-ring 1.2s linear infinite;
}

@-webkit-keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>