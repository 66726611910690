import axios from "@/plugins/axios";
import {AxiosResponse} from "axios";

const resource = 'api/v1/file';

export default {
    getByIdFileAndModuleDesignation(idFile: number, moduleDesignation: string): Promise<AxiosResponse<IServerResponse<any>>> {
        return axios.get(`${resource}/${idFile}/module/${moduleDesignation}`, {responseType: 'blob'});
    },

    getByIdProfessionalAndIdClientAndModuleDesignation(idProfessional: number, moduleDesignation: string): Promise<AxiosResponse<IServerResponse<IFile[]>>> {
        return axios.get(`${resource}/professional/${idProfessional}/client/module/${moduleDesignation}`);
    },

    getByIdClientAndIdUserAndModuleDesignation(idClient: number, moduleDesignation: string): Promise<AxiosResponse<IServerResponse<IFile[]>>> {
        return axios.get(`${resource}/user/client/${idClient}/module/${moduleDesignation}`);
    },

    postUploadFile(moduleDesignation: string, file): Promise<AxiosResponse<IServerResponse<void>>> {
        return axios.post(`${resource}/user/module/${moduleDesignation}`, file);
    },

    putEditFilename(idFile: number, moduleDesignation: string, filename: string): Promise<AxiosResponse<void>> {
        return axios.put(`${resource}/${idFile}/user/module/${moduleDesignation}`, {filename: filename});
    },

    deleteFile(idFiles, moduleDesignation: string): Promise<AxiosResponse<void>> {
        return axios.delete(`${resource}/user/module/${moduleDesignation}`, {data: idFiles});
    },
}