import UserClientRepository from "@/repositories/UserClientRepository";

const state = () => ({
    domiciles: [],
});

const mutations = {
    setDomiciles(state, domiciles: IDomicile[]): void {
        state.domiciles = domiciles;
    },
};

const actions = {
    async getUserClientDomiciles({commit}, idUser: number): Promise<void> {
        await UserClientRepository.getDomicilesByUserClient(idUser).then((success) => {
            commit('setDomiciles', success.data.data);
        });
    },
};

const getters = {
    domiciles: (state) => state.domiciles,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
