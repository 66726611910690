import OrderCockpitRepository from "@/repositories/OrderCockpitRepository";

const state = () => ({
    orders_cockpit_pending_validation: [],
});

const mutations = {
    setOrdersCockpitPendingValidation(state, ordersCockpitPendingValidation: IOrderCockpit[]): void {
        state.orders_cockpit_pending_validation = ordersCockpitPendingValidation;
    }
};

const actions = {
    async getOrdersCockpitPendingValidation({commit}): Promise<void> {
        await OrderCockpitRepository.getOrderCockpitListByState(1).then((success) => {
            commit('setOrdersCockpitPendingValidation', success.data.data);
        });
    },
};

const getters = {
    orders_cockpit_pending_validation: (state) => state.orders_cockpit_pending_validation,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
