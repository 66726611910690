<template>
    <div class="profile-picture-circle" id="profile-picture-component">
        <span class="profile-picture-initial">
            {{
                $store.getters["user_connected/user_connected"].first_name ?
                    $store.getters["user_connected/user_connected"].first_name.charAt(0) + $store.getters["user_connected/user_connected"].last_name.charAt(0) : ''
            }}
        </span>
    </div>
</template>

<script>

export default {
    name: 'ProfilePictureComponent'
}
</script>

<style>
.profile-picture-circle {
    width: 2em;
    aspect-ratio: 1;
    border-radius: 100%;
    background: #38cda2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile-picture-initial {
    font-size: 1em;
    color: white !important;
}
</style>