import ClientRepository from "@/repositories/ClientRepository";

const state = () => ({
    tanks: []
});

const mutations = {
    setTanks(state, tanks: IAssetCompleteInformation[]): void {
        state.tanks = tanks;
    },
};

const actions = {
    async getTanksByClient({commit}, payload): Promise<void> {
        await ClientRepository.getUserAssetsInformationByAssetTypeAndIdClient(
            payload.id_client, payload.id_referential
        ).then((success) => {
            commit('setTanks', success.data.data);
        });
    }
};

const getters = {
    tanks: (state) => state.tanks,
    getTankById: (state) => (id_tank) => state.tanks ? state.tanks.find(tank => tank.id_asset === parseInt(id_tank)) : null,
    getDevicesContractPaidByPro: (state) => {
        let result = false;
        state.tanks.forEach(tank => {
            tank.devices.forEach(device => {
                if (device.contract_paid_by_professional) {
                    result = true;
                }
            });
        });
        return result;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
