const state = () => ({
    show_left_navbar: true,
    show_bottom_navbar: true
});

const mutations = {
    manageNavbar(state, params): void {
        state.show_left_navbar = params.show_left_navbar;
        state.show_bottom_navbar = params.show_bottom_navbar;
    }
};

const getters = {
    show_left_navbar: (state) => state.show_left_navbar,
    show_bottom_navbar: (state) => state.show_bottom_navbar,
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
};
