import axios from '../plugins/axios';
import {AxiosResponse} from "axios";

const resource = 'api/v1/client';

export default {
    getUserAssetsInformationByAssetTypeAndIdClient(idClient: number, idAssetType: number): Promise<AxiosResponse<IServerResponse<IAssetCompleteInformation[]>>> {
        return axios.get(`${resource}/${idClient}/asset_type/${idAssetType}/assets`);
    },

    getUserDatatable(idClient: number): Promise<AxiosResponse<IServerResponse<IUserDatatable[]>>> {
        return axios.get(`${resource}/${idClient}/users/datatable`);
    },

    getClientById(idClient: number): Promise<AxiosResponse<IServerResponse<IClient>>> {
        return axios.get(`${resource}/${idClient}`);
    },

    putEditUserRole(idClient: number, idUser: number, idRoleClient: number): Promise<AxiosResponse<IServerResponse<any>>> {
        return axios.put(`${resource}/${idClient}/user/${idUser}/role`, {id_role_client: idRoleClient});
    },

    updateClient(idClient: number, client): Promise<AxiosResponse<IServerResponse<any>>> {
        return axios.put(`${resource}/${idClient}`, client);
    },

    deleteUserAccess(idClient: number, data): Promise<AxiosResponse<IServerResponse<any>>> {
        return axios.delete(`${resource}/${idClient}/user/access`, {data: data});
    },
}