import DisplayOptionRepository from "@/repositories/DisplayOptionRepository";

const state = () => ({
    display_option: {},
    logo: '/images/display_option/logo.png',
    background_login: '/images/display_option/bg_login.jpg',
});

const mutations = {
    setDisplayOption(state, displayOption: IDisplayOption): void {
        state.display_option = displayOption;
        state.logo = displayOption.src_logo;
        state.background_login = displayOption.src_background_login;
    },
};

const actions = {
    async getDisplayOptionByPwaSensUrl({commit}): Promise<void> {
        await DisplayOptionRepository.getDisplayOptionByPwaSensUrl().then((success) => {
            commit('setDisplayOption', success.data.data);
        });
    },

    async getDisplayOptionByIdProfessional({commit}, idProfessional: number): Promise<void> {
        await DisplayOptionRepository.getDisplayOptionByIdProfessional(idProfessional).then((success) => {
            commit('setDisplayOption', success.data.data);
        });
    }
};

const getters = {
    display_option: (state) => state.display_option,
    logo: (state) => state.logo,
    background_login: (state) => state.background_login
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
