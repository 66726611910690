import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';
import fr from 'vuetify/lib/locale/fr';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#BCC20E',
                primary_dark: '#949A00',
                primary_light: '#CFD431',
                secondary: '#00626F',
                secondary_dark: '#00363D',
                secondary_light: '#288A97',
                accent: '#D3D3D3',
                error: '#D9534F',
                info: '#32b3ee',
                success: '#5CB85C',
                warning: '#F0AD4E',
                white: '#ffffff',
                black: '#1e1e1e',
                background: '#F3F6FA',
                default_text: '#4C545F',
                default_text_light: '#a6aab4',
                default_text_medium: '#7f828a'
            },
            dark: {
                primary: '#BCC20E',
                primary_dark: '#949A00',
                primary_light: '#CFD431',
                secondary: '#00626F',
                secondary_dark: '#00363D',
                secondary_light: '#288A97',
                accent: '#D3D3D3',
                error: '#D9534F',
                info: '#32b3ee',
                success: '#5CB85C',
                warning: '#F0AD4E',
                white: '#1e1e1e',
                black: '#ffffff',
                background: '#35383b',
                default_text: '#e5e8ec',
                default_text_light: '#d6d8de',
                default_text_medium: '#acaeb4'
            },
        },

    },
    lang: {
        locales: {fr},
        current: 'fr'
    }
});
