import moment from "moment";
import {version} from "../../package.json";
import AssetRepository from "@/repositories/AssetRepository";
import FileRepository from "@/repositories/FileRepository";

export default {
    data: () => ({
        drawer: true,
        is_online: navigator.onLine,
        role_admin: 4,
        role_all_access: 3,
        role_edit: 2,
        role_vision: 1,
        role_no_access: 0,
        app_version: version
    }),
    computed: {
        is_mobile() {
            let isMobile = false;
            if (this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs) {
                isMobile = true;
                this.drawer = false;
            } else {
                this.drawer = true;
            }

            return isMobile;
        },
        language() {
            return navigator.language || 'fr-FR';
        },
        date_format() {
            return this.getFormatDateBaseOnLanguage().dateFormat;
        },
        date_format_chart() {
            return this.getFormatDateBaseOnLanguage().dateFormatChart;
        },
        date_format_csv() {
            return this.getFormatDateBaseOnLanguage().dateFormatCsv;
        },
        page_name_equipment() {
            return (this.$store.getters['asset_types/asset_types_which_have_asset'].length !== 1 ? 'Equipements' : this.$t(`equipment_page.${this.$store.getters['asset_types/asset_types_which_have_asset'][0].key}`));
        }
    },
    watch: {
        is_online() {
            if (!this.is_online) {
                this.showSnackbar('error', 'Aucune connexion internet.');
            }
        }
    },
    methods: {
        navigateTo(url) {
            this.$router.push({name: url});
        },

        userHasAccess(designation, levelPermission, redirection = false) {
            let hasAccess = false;
            let permission = this.$store.getters['user_connected/getPermissionByDesignation'](designation);
            if(permission) {
                if(permission.level_permission >= levelPermission) {
                    hasAccess = true;
                }
            }
            if(redirection && !hasAccess){
                this.logout();
            }
            return hasAccess;
        },

        manageError(error) {
            if (!this.is_online) {
                this.showSnackbar('error', 'Aucune connexion internet.');
            } else {
                let type = 'error';
                let text = this.$t('error_display.error_global');

                if (error.response !== undefined) {
                    text = 'Un problème est survenu - Veuillez réessayer plus tard';

                    if(process.env.NODE_ENV !== 'production') {
                        text += ' (' + error.response.data.message + ')';
                    }

                    if (this.$te((`error_display.${error.response.data.translation_key}`))) {
                        text = this.$t(`error_display.${error.response.data.translation_key}`);
                    }
                }

                this.showSnackbar(type, text)
            }
        },

        showSnackbar(type, text) {
            this.$store.dispatch('snackbar/showSnackbar', {snackbar_type: type, snackbar_text: text});
        },

        numberWithSpaces(value) {
            let result = value;
            if (value) {
                result = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
            }
            return result;
        },

        moment(date) {
            moment.locale(this.language);
            return moment(date);
        },

        logout() {
            this.$store.dispatch('general/setIdProfessional', null);
            this.$auth.logout({});
        },

        openOrCloseWidgetEquipment(componentTitle) {
            this.$store.dispatch('page_order_configuration/openOrCloseWidgetEquipment',{
                component_title: componentTitle, id_page_order: this.$store.getters['page_order_configuration/page_order_configuration_equipment'].id_page_order
            });
        },

        deleteWidgetEquipment(widget_name) {
            let order_configuration_equipment = this.$store.getters['page_order_configuration/order_configuration_equipment'];
            order_configuration_equipment.forEach(config => {
                if(config.widget_name === widget_name){
                    config.is_display = false;
                }
            });

            this.$store.dispatch('page_order_configuration/updatePageOrderConfigurationEquipment', {
                page: 'equipment',
                order_configuration: order_configuration_equipment
            }).then(() => {
                this.showSnackbar('success', 'Widget supprimé');
            }).catch((error) => {
                this.manageError(error);
            });
        },

        calculateNbNotificationsByModule(module) {
            let totalNotification = 0;
            if(module === 'orders_cockpit') {
                totalNotification = this.$store.getters['orders_cockpit/orders_cockpit_pending_validation'].length;
            }
            return totalNotification;
        },

        getFormatDateBaseOnLanguage() {
            let dateFormat = '';
            let dateFormatChart = '';
            let dateFormatCsv = '';

            switch (this.language) {
                case 'en-GB':
                    dateFormat = 'YYYY/MM/D';
                    dateFormatChart = '%Y/%m/%d %H:%M:%S';
                    dateFormatCsv = 'YYYY/MM/D HH:mm:ss';
                    break;
                case 'fr-FR':
                default:
                    dateFormat = 'DD/MM/Y';
                    dateFormatChart = '%d/%m/%Y %H:%M:%S';
                    dateFormatCsv = 'DD/MM/Y HH:mm:ss';
                    break;
            }

            return {
                'dateFormat': dateFormat,
                'dateFormatChart': dateFormatChart,
                'dateFormatCsv': dateFormatCsv
            };
        },

        setAllInformationAfterConnect(id_professional, user) {
            if(this.$router.currentRoute.name !== 'equipment') {
                this.$router.push({name: 'equipment'});
            }

            this.$store.dispatch('user_connected/setAuthenticateUser', user);
            this.$store.dispatch('general/setGlobalLoader', true);
            this.$store.dispatch('general/setIdProfessional', id_professional);

            this.getClientsOfUser();
            this.getNavbarConfiguration();
            this.getPageOrderConfiguration();
            this.getProfessionalAssetTypes();
            this.getUserClientDomiciles();
            this.getNotifications();
            this.getDisplayOption(id_professional);

            if(this.userHasAccess('ORDC', this.role_vision)) {
                this.getOrdersCockpitPendingValidation();
            }
        },

        getDisplayOption(id_professional) {
            this.$store.dispatch(
                'display_option/getDisplayOptionByIdProfessional', id_professional
            ).then(() => {
                this.setDisplayOption();
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
                this.$store.dispatch('general/setGlobalLoader', false);
            });
        },

        setDisplayOption() {
            let displayOption = this.$store.getters['display_option/display_option'];
            this.$vuetify.theme.themes.light.primary = this.$vuetify.theme.themes.dark.primary = displayOption.primary;
            this.$vuetify.theme.themes.light.primary_dark = this.$vuetify.theme.themes.dark.primary_dark = displayOption.primary_dark;
            this.$vuetify.theme.themes.light.primary_light = this.$vuetify.theme.themes.dark.primary_light = displayOption.primary_light;
            this.$vuetify.theme.themes.light.secondary = this.$vuetify.theme.themes.dark.secondary = displayOption.secondary;
            this.$vuetify.theme.themes.light.secondary_dark = this.$vuetify.theme.themes.dark.secondary_dark = displayOption.secondary_dark;
            this.$vuetify.theme.themes.light.secondary_light = this.$vuetify.theme.themes.dark.secondary_light = displayOption.secondary_light;
            this.$vuetify.theme.themes.light.error = this.$vuetify.theme.themes.dark.error = displayOption.error;
            this.$vuetify.theme.themes.light.warning = this.$vuetify.theme.themes.dark.warning = displayOption.warning;
            this.$vuetify.theme.themes.light.success = this.$vuetify.theme.themes.dark.success = displayOption.success;
            document.getElementById("favicon").href = displayOption.src_favicon;
        },

        getNavbarConfiguration() {
            this.$store.dispatch('user_navbar_configuration/getNavbarConfigurations').catch((error) => {
                this.manageError(error);
            });
        },

        getUserClientDomiciles() {
            this.$store.dispatch('domiciles/getUserClientDomiciles', this.$store.getters['user_connected/user_connected'].id_user).catch((error) => {
                this.manageError(error);
            });
        },

        getPageOrderConfiguration() {
            this.$store.dispatch('page_order_configuration/getPageOrderConfigurationEquipment').catch((error) => {
                this.manageError(error);
            });
        },

        getClientsOfUser() {
            this.$store.dispatch('clients/getClientsOfUser', this.$store.getters['user_connected/user_connected'].id_user).catch((error) => {
                this.manageError(error);
            });
        },

        getOrdersCockpitPendingValidation() {
            this.$store.dispatch('orders_cockpit/getOrdersCockpitPendingValidation').catch((error) => {
                this.manageError(error);
            });
        },

        getNotifications() {
            this.$store.dispatch('notifications/getNotifications', this.$store.getters['user_connected/user_connected'].id_client).catch((error) => {
                this.manageError(error);
            });
        },

        getProfessionalAssetTypes() {
            this.$store.dispatch(
                'asset_types/getProfessionalAssetTypes', this.$store.getters['user_connected/user_connected'].id_professional
            ).then(() => {
                this.getAssetByAssetTypeAndClient();
            }).catch((error) => {
                if (error.response.data.translation_key === '') {
                    error.response.data.translation_key = 'retrieving_equipment_error';
                }
                this.manageError(error);
            });
        },

        getAssetByAssetTypeAndClient() {
            this.$store.dispatch('asset_types/resetAssetTypeWithAsset');

            this.$store.getters['asset_types/asset_types'].forEach((asset_type) => {
                AssetRepository.getAssetByAssetTypeAndClient(
                    this.$store.getters['user_connected/user_connected'].id_client, asset_type.id_referential
                ).then((success) => {
                    if(success.data.data.length > 0) {
                        this.$store.dispatch('asset_types/setAssetTypesWhichHaveAsset', asset_type);
                    }
                }).catch((error) => {
                    this.manageError(error);
                })
            });
        },

        setDateForRequest(date){
            return this.moment(date).format('YYYY-MM-DD');
        },

        getFormattedAddress(address) {
            return address.address + ' ' + address.postal_code + ' ' + address.city + ' ' + address.country;
        },

        compareDate(start, end = this.moment()) {
            /* -1 older // 0 equal // 1 younger */
            let result = moment(start).toDate() === moment(end).toDate() ? 0 : -1;
            if(moment(start).toDate() > moment(end).toDate()) {
                result = 1;
            }
            return result;
        },

        downloadCsvFile(csvData, title) {
            const csvBlob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csvData.join('\n')])
            let anchorElement = window.document.createElement('a');
            anchorElement.href = window.URL.createObjectURL(csvBlob);
            anchorElement.download = title + '_' + this.moment().format(this.date_format) + '.csv';

            document.body.appendChild(anchorElement);
            anchorElement.click();
            document.body.removeChild(anchorElement);
            window.URL.revokeObjectURL(anchorElement.href);
        },

        downloadFile(file, moduleDesignation) {
            FileRepository.getByIdFileAndModuleDesignation(file.id_file, moduleDesignation).then((success) => {
                const url = URL.createObjectURL(new Blob([success.data]));
                const link = document.createElement('a');
                link.href = url;
                link.download = file.filename + file.file_type.extension;
                document.body.appendChild(link);
                link.click();
            }).catch((error) => {
                this.manageError(error);
            });
        },

        getTunnelInstallationUrl() {
            switch (process.env.VUE_APP_API_URL) {
                case 'https://api-pwa.quatre-factorielle.com':
                    return 'https://installation.fourdata.io/installation';
                case 'https://prp-api-pwa.quatre-factorielle.com':
                    return 'https://prp-installation.wave-connect.io/installation';
                default:
                    return "http://localhost:8082/installation";
            }
        }
    },
    mounted() {
        window.addEventListener('online', () => {
            this.is_online = navigator.onLine;
        });
        window.addEventListener('offline', () => {
            this.is_online = navigator.onLine;
        });
    },
}