import axios from '../plugins/axios';
import {AxiosResponse} from "axios";

const resource = 'api/v1/professional';

export default {
    getProfessionalAssetTypes(idProfessional: number): Promise<AxiosResponse<IServerResponse<IReferential[]>>> {
        return axios.get(`${resource}/${idProfessional}/asset_types`);
    },

    getClientRole(idProfessional: number): Promise<AxiosResponse<IServerResponse<IRoleClient[]>>> {
        return axios.get(`${resource}/${idProfessional}/roles_client`);
    },

    getAssetLiquidsByProfessionalAndType(idProfessional: number, type: string): Promise<AxiosResponse<IServerResponse<IAssetLiquid[]>>> {
        return axios.get(`${resource}/${idProfessional}/asset_liquids/type/${type}`);
    }
}