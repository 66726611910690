import Pushy from "pushy-sdk-web";
import UserRepository from "@/repositories/UserRepository";

class PushyManager {
    constructor(Vue, options) {
        this.app = Vue;
        this.options = options;
        if ("store" in options) this.$store = options.store;
        
        this.is_registered = false
    }

    /** Retrieves the user's token. */
    register() {
        if (!this.is_registered) {
            if(this.$store.getters['pushy/isMobile']) {
                this.is_registered = true
                UserRepository.registerPushyToken(this.$store.getters['pushy/token'], this.$store.getters['pushy/platform'], this.$store.getters['pushy/type'])
            } else {
                this.#generateToken()
    
                Pushy.setNotificationListener(function() {
                    this.dispatchNotifications();
                }.bind(this));
            }
        }
    }

    /** Processing an incoming Pushy notification */
    dispatchNotifications() {
        this.$store.dispatch("notifications/getNotifications", this.$store.getters["user_connected/user_connected"].id_client);
    }

    /** Sends a request to retrieve the token. */
    #generateToken() {
        Pushy.register({ appId: this.retrieveAppId() })
            .then(
                function (deviceToken) {
                    this.is_registered = true
                    UserRepository.registerPushyToken(deviceToken, this.retrievePlatform(), 'web')
                }.bind(this)
            )
            .catch(function (err) {
                console.error(err);
            });
    }

    retrieveAppId() {
        switch(window.location.hostname) {
            case 'prp.sens.quatre-factorielle.com':
                return '62e0de54a499748c0e6cd3a4'
            case 'sens.fourdata.io':
                return '63215f40e47b54ce3c28f1fc'
        }

        UserRepository.platformDoesNotExists(window.location.hostname);
    }

    retrievePlatform() {
        switch(window.location.hostname) {
            case 'prp.sens.quatre-factorielle.com':
                return 'empty-shell'
            case 'sens.fourdata.io':
                return 'fourdata'
        }

        UserRepository.platformDoesNotExists(window.location.hostname);
    }
}

export default {
    install(Vue, options) {
        Vue.pushy = new PushyManager(Vue, options);

        Object.defineProperties(Vue.prototype, {
            $pushy: {
                get: function () {
                    return Vue.pushy;
                },
            },
        });
    },
};
