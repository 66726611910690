import axios from '../plugins/axios';
import {AxiosResponse} from "axios";

const resource = 'api/v1/user_client';

export default {
    postSendEmailResetPassword(data): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.post(`${resource}/invitation/reset_password`, data);
    },

    postCheckIfUserClientCanResetPassword(data): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.post(`${resource}/can_reset_password`, data);
    },

    postResetPassword(data): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.post(`${resource}/reset_password`, data);
    },

    postCreateAccount(data): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.post(`${resource}/account`, data);
    },

    getCheckTokenUserClient(token: string): Promise<AxiosResponse<IServerResponse<any>>> {
        return axios.get(`/api/v1/token_user_client/check?token=${token}`);
    },

    getDomicilesByUserClient(id_client: number): Promise<AxiosResponse<IServerResponse<IDomicile[]>>> {
        return axios.get(`${resource}/${id_client}/domiciles`);
    }
}