import NotificationRepository from "@/repositories/NotificationRepository";

const state = () => ({
    notifications: []
});

const mutations = {
    setNotifications(state, notifications: INotification): void {
        state.notifications = notifications;
    },

    setNotificationsSee(state): void {
        state.notifications.forEach((notification) => {
            notification.is_see = true;
        });
    },

    setNotificationRead(state, idNotification: number): void {
        state.notifications.forEach((notification) => {
            if (notification.id_notification === idNotification) {
                notification.is_read = true;
            }
        });
    },

    setNotificationsReadAndSeeWithIdNotificationCategory(state, idNotificationCategory: number): void {
        state.notifications.forEach((notification) => {
            if (notification.notification_category.id_notification_category === idNotificationCategory) {
                notification.is_read = true;
                notification.is_see = true;
            }
        })
    },

    deleteNotificationById(state, indexNotification: number): void {
        state.notifications.splice(indexNotification, 1);
    },
};

const actions = {
    async getNotifications({commit}, idClient: number): Promise<void> {
        await NotificationRepository.getNotificationByIdUserAndIdClient(idClient).then((success) => {
            commit('setNotifications', success.data.data);
        });
    },

    async setNotificationsSee({commit}): Promise<void> {
        commit('setNotificationsSee');
    },

    async setNotificationRead({commit}, idNotification: number): Promise<void> {
        commit('setNotificationRead', idNotification);
    },

    async setNotificationsReadAndSeeWithIdNotificationCategory({commit}, idNotificationCategory: number): Promise<void> {
        commit('setNotificationsReadAndSeeWithIdNotificationCategory', idNotificationCategory);
    },

    async deleteNotificationById({commit}, indexNotification: number): Promise<void> {
        await commit('deleteNotificationById', indexNotification);
    },
};

const getters = {
    notifications: (state) => state.notifications,
    total_notifications_not_see: (state) => {
        return state.notifications.filter(x => !x.is_see).length;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
