import UserRepository from "@/repositories/UserRepository";

const state = () => ({
    clients: []
});

const mutations = {
    setClients(state, clients: IClient[]): void {
        state.clients = clients;
    },
};

const actions = {
    async getClientsOfUser({commit}, idUser: number): Promise<void> {
        await UserRepository.getClientsOfUser(
            idUser
        ).then((success) => {
            commit('setClients', success.data.data);
        });
    },
};

const getters = {
    clients: (state): void => state.clients,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
