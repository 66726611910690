<template>
    <div style="display: none;">
        <v-text-field id="json-notification" v-model="jsonNotification" style="display: none;" />
        <v-text-field id="pushy-token" v-model="token" style="display: none;" />
        <v-text-field id="pushy-platform" v-model="platform" style="display: none;" />
        <v-text-field id="pushy-type" v-model="type" style="display: none;" />
    </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
    name: "HiddenInputNotifications",
    data() {
        return {
            jsonNotification: "",
            token: "",
            type: "",
            platform: "",
        };
    },
    watch: {
        jsonNotification: {
            handler(newValue) {
                if (newValue !== undefined && newValue !== null && newValue !== "") {
                    this.$pushy.dispatchNotifications();
                }
            },
            deep: true,
            immediate: true,
        },
        token: {
            handler(newValue) {
                if (newValue !== undefined && newValue !== null && newValue !== "") {
                    this.$store.dispatch('pushy/isMobile', {'token': this.token, 'type': this.type, 'platform': this.platform});
                }
            },
            deep: true,
            immediate: true,
        },
    },
});
</script>
