import axios from '../plugins/axios';
import {AxiosResponse} from "axios";

const resource = 'api/v1/user';

export default {
    getAuthenticateUser(): Promise<AxiosResponse<IServerResponse<IUserAuthenticate>>> {
        return axios.get(`/auth/me`);
    },

    getClientsOfUser(idUser: number): Promise<AxiosResponse<IServerResponse<IClient[]>>> {
        return axios.get(`${resource}/${idUser}/clients`);
    },

    getSwitchClientAsUserClient(idUser: number, idClient: number): Promise<AxiosResponse<IServerResponse<string>>> {
        return axios.get(`${resource}/${idUser}/switch/client/${idClient}`);
    },

    getUserInvitation(idUser: string): Promise<AxiosResponse<IServerResponse<IUserInvitation>>> {
        return axios.get(`${resource}/${idUser}/user_invitations`);
    },

    updateUserProfile(user: IUser): Promise<AxiosResponse<IServerResponse<IUser>>> {
        return axios.put(`${resource}`, user);
    },

    updateUserPassword(oldPassword: string, newPassword: string): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.put(`${resource}/password`, {
            old_password: oldPassword,
            new_password: newPassword
        });
    },

    deleteUserAccess(idClient: number): Promise<AxiosResponse<IServerResponse<any>>> {
        return axios.delete(`${resource}/client/${idClient}/access`);
    },

    registerPushyToken(token: string, platform: string, type: string): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.post(`${resource}/register_push_token`, {
            token: token,
            type: type,
            platform: platform,
        });
    },

    platformDoesNotExists(url: string): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.post(`${resource}/unregistered_platform`, {
            url: url,
        });
    },
}