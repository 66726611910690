import ProfessionalRepository from "@/repositories/ProfessionalRepository";

const state = () => ({
    asset_types: [],
    asset_types_which_have_asset: []
});

const mutations = {
    setAssetTypes(state, assetTypes: IReferential[]): void {
        state.asset_types = assetTypes;
    },

    setAssetTypesWhichHaveAsset(state, assetType: IReferential): void {
        if (state.asset_types_which_have_asset.length === 0) {
            state.asset_types_which_have_asset.push(assetType);
        } else {
            state.asset_types_which_have_asset.filter((asset_type) => {
                if (asset_type.id_referential !== assetType.id_referential) {
                    state.asset_types_which_have_asset.push(assetType);
                }
            });
        }
    },

    resetAssetTypeWithAsset(state): void {
        state.asset_types_which_have_asset = [];
    }
};

const actions = {
    async getProfessionalAssetTypes({commit}, idProfessional: number): Promise<void> {
        await ProfessionalRepository.getProfessionalAssetTypes(
            idProfessional
        ).then((success) => {
            commit('setAssetTypes', success.data.data);
        });
    },

    async setAssetTypesWhichHaveAsset({commit}, assetType: IAssetCompleteInformation[]): Promise<void> {
        commit('setAssetTypesWhichHaveAsset', assetType);
    },

    async resetAssetTypeWithAsset({commit}): Promise<void> {
        commit('resetAssetTypeWithAsset');
    },
};

const getters = {
    asset_types: (state) => state.asset_types,
    asset_types_which_have_asset: (state) => state.asset_types_which_have_asset
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
