import DeviceRepository from "@/repositories/DeviceRepository";

const state = () => ({
    alerts: []
});

const mutations = {
    setAlerts(state, alerts: IAlert[]): void {
        state.alerts = alerts;
    }
};

const actions = {
    async getAlerts({commit}, tanks): Promise<void> {
        const local_alerts: IAlert[] = [];
        for (const tank of tanks) {
            for (const device of tank.devices) {
                await DeviceRepository.getAllAlertByIdDevice(
                    device.id_device
                ).then((success) => {
                    success.data.data.forEach(alert => {
                        local_alerts.push(alert);
                    });
                });
            }
        }
        commit('setAlerts', local_alerts);
    }
};

const getters = {
    alerts: (state): void => state.alerts,
    alertsByDevice: (state) => (id_device) : void => state.alerts ? state.alerts.filter(alert => alert.id_device === id_device) : null
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};