import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import store from '@/store/store';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        redirect: '/login',
        meta: {auth: false},
        beforeEnter: (to, from, next) => {
            manageNavbar(false, false);
            next();
        }
    },
    {
        path: '/login:param?',
        component: () => import('../views/LoginPage.vue'),
        meta: {auth: false},
        beforeEnter: (to, from, next) => {
            manageNavbar(false, false);
            next();
        }
    },
    {
        path: '*',
        redirect: '/equipment',
        component: () => import('../views/EmptyPage.vue'),
        meta: {auth: true},
    },
    {
        path: '/404',
        redirect: '/equipment',
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            manageNavbar(false, false);
            next();
        }
    },
    {
        path: '/equipment',
        name: 'equipment',
        component: () => import('../views/Equipment/EquipmentPage.vue'),
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            manageNavbar(true, true);
            next();
        }
    },
    {
        path: '/equipment/tank/:id',
        name: 'tank',
        component: () => import('../views/Equipment/DetailTankPage.vue'),
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            manageNavbar(false, false);
            next();
        }
    },
    {
        path: '/parameter',
        name: 'parameter',
        component: () => import('../views/ParameterPage.vue'),
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            manageNavbar(false, false);
            next();
        }
    },
    {
        path: '/parameter/customisation',
        component: () => import('../views/Parameter/Customisation/CustomisationPage.vue'),
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            manageNavbar(false, false);
            next();
        }
    },
    {
        path: '/parameter/customisation/navigation',
        name: 'parameter_customisation_navigation',
        component: () => import('../views/Parameter/Customisation/CustomisationNavigationPage.vue'),
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            manageNavbar(false, false);
            next();
        }
    },
    {
        path: '/parameter/comment',
        name: 'parameter_comment',
        component: () => import('../views/Parameter/Comment/SendCommentPage.vue'),
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            manageNavbar(false, false);
            next();
        }
    },
    {
        path: '/parameter/comment/opinion',
        name: 'parameter_comment_opinion',
        component: () => import('../views/Parameter/Comment/SendOpinionPage.vue'),
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            manageNavbar(false, false);
            next();
        }
    },
    {
        path: '/parameter/comment/bug',
        name: 'parameter_comment_bug',
        component: () => import('../views/Parameter/Comment/SendBugPage.vue'),
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            manageNavbar(false, false);
            next();
        }
    },
    {
        path: '/parameter/management_user',
        name: 'management_user',
        component: () => import('../views/ManagementUserPage.vue'),
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            manageNavbar(false, true);
            next();
        }
    },
    {
        path: '/parameter/manage_space',
        name: 'manage_space',
        component: () => import('../views/Parameter/ManageSpacePage.vue'),
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            manageNavbar(false, false);
            next();
        }
    },/*
    {
        path: '/parameter/subscription_payment',
        name: 'subscription_payment',
        component: () => import('../views/Parameter/Subscription/SubscriptionPaymentPage.vue'),
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            manageNavbar(false, false);
            if (store.getters['tanks/getDevicesContractPaidByPro']) {
                next('/parameter');
            }
            next();
        }
    },
    {
        path: '/parameter/subscription_payment/subscription',
        name: 'subscription',
        component: () => import('../views/Parameter/Subscription/SubscriptionPage.vue'),
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            manageNavbar(false, false);
            if (store.getters['tanks/getDevicesContractPaidByPro']) {
                next('/parameter');
            }
            next();
        }
    },*/
    {
        path: '/documents',
        name: 'documents',
        component: () => import('../views/DocumentPage.vue'),
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            manageNavbar(true, true);
            next();
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('../views/ProfilePage.vue'),
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            manageNavbar(false, false);
            next();
        }
    },
    {
        path: '/notifications',
        name: 'notifications',
        component: () => import('../views/NotificationPage.vue'),
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            manageNavbar(false, false);
            next();
        }
    },
    {
        path: '/gear',
        name: 'gear',
        component: () => import('../views/EnginPage.vue'),
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            manageNavbar(true, true);
            next();
        }
    },
    {
        path: '/orders_cockpit',
        name: 'orders_cockpit',
        component: () => import('../views/OrderCockpit/OrderCockpitPage.vue'),
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            manageNavbar(true, true);
            next();
        }
    },
    {
        path: '/orders_cockpit/:id',
        name: 'order_cockpit',
        component: () => import('../views/OrderCockpit/DetailsOrderCockpitPage.vue'),
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            manageNavbar(false, false);
            next();
        }
    },
    {
        path: '/stickers/:show_alert?',
        name: 'stickers',
        component: () => import('../views/Stickers/StickersPage.vue'),
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            manageNavbar(true, true);
            next();
        }
    },
    {
        path: '/stickers/asking_stickers',
        name: 'asking_stickers',
        component: () => import('../views/Stickers/AskingStickersPage.vue'),
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            manageNavbar(false, true);
            next();
        }
    },
    {
        path: '/equipment/manage_alert/:id',
        name: 'manage_alert',
        component: () => import('../views/Alert/ManageAlertPage.vue'),
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            manageNavbar(false, false);
            next();
        }
    },
    {
        path: '/news',
        name: 'news',
        component: () => import('../views/NewsPage.vue'),
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            manageNavbar(true, true);
            next();
        }
    }
];

function manageNavbar(show_left_navbar, show_bottom_navbar) {
    store.commit('manage_navbar/manageNavbar', {
        show_left_navbar: show_left_navbar,
        show_bottom_navbar: show_bottom_navbar,
    })
}

const router = new VueRouter({
    mode: 'history',
    routes: routes
});

export default router;
