<template>
    <div id="dialog-contact-component">
        <v-dialog v-model="dialog_contact" max-width="500px">
            <v-card>
                <v-container>
                    <v-card-title>
                        <p class="text-uppercase font-weight-bold">Besoin d'aide</p>
                    </v-card-title>

                    <v-card-subtitle>
                        <p class="mb-2 font-14 line-height-16">Vous rencontrez un problème ?</p>
                        <p class="font-14 line-height-16">Contactez-nous. Nos équipes répondront à votre problèmatique
                            dans les meilleurs délais</p>
                    </v-card-subtitle>

                    <v-card-text class="pb-0">
                        <v-select :menu-props="{ bottom: true, offsetY: true }" label="Votre problème concerne"
                                  item-value="id" item-text="label" :items="problems_encountered" filled
                                  v-model="problem_encountered_id"
                        />

                        <v-textarea v-model="message" filled label="Message"/>

                        <p class="font-weight-light font-color-medium font-12 line-height-14">
                            Certaines informations concernant le compte et le système pourront être envoyées à Oleo100.
                            Nous les utiliserons pour résoudre des problèmes et améliorer nos services, conformément à
                            nos <a>Règles de confidentialité</a> et à nos <a>Conditions d'utilisation</a>. Nous pourrons
                            vous demander plus d'informations ou vous en renvoyer par e-mail.
                        </p>
                    </v-card-text>

                    <v-card-actions class="justify-end">
                        <v-btn text @click="dialog_contact = false">
                            <span class="font-color-light font-weight-bold">{{ $t('global.cancel') }}</span>
                        </v-btn>

                        <v-btn color="secondary" text @click="sendAssistanceRequired()">
                            <span class="secondary--text font-weight-bold">{{ $t('global.validate') }}</span>
                        </v-btn>
                    </v-card-actions>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import GlobalRepository from "@/repositories/GlobalRepository";

export default {
    name: 'DialogContactComponent',
    data() {
        return {
            dialog_contact: false,
            message: '',
            problem_encountered_id: null,
            problems_encountered: [
                {id: 1, label: 'Cuve'},
                {id: 2, label: 'Commande'},
                {id: 3, label: 'Produit'},
                {id: 4, label: 'Véhicule'},
                {id: 5, label: 'Communication'},
                {id: 6, label: 'Contrat et facturation'}
            ]
        }
    },
    methods: {
        sendAssistanceRequired() {
            GlobalRepository.sendAssistanceRequired({
                'id_problem': this.problem_encountered_id,
                'message': this.message
            }).then(() => {
                this.showSnackbar('success', 'Demande d\'assistance envoyée.');
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
                this.dialog_contact = false;
                this.problem_encountered_id = null;
                this.message = '';
            })
        }
    }
}
</script>