import axios from '../plugins/axios';
import {AxiosResponse} from "axios";

const resource = 'api/v1/user_configuration';

export default {
    getUserNavbarConfigurationByConnectedUser(): Promise<AxiosResponse<IServerResponse<IUserNavbarConfiguration>>> {
        return axios.get(`${resource}/navbar`);
    },

    postUserNavbarConfiguration(navbarConfiguration: IUserNavbarConfiguration[]): Promise<AxiosResponse<IServerResponse<IUserNavbarConfiguration>>> {
        return axios.post(`${resource}/navbar`, navbarConfiguration);
    },

    updateUserNavbarConfiguration(navbarConfiguration: IUserNavbarConfiguration[]): Promise<AxiosResponse<IServerResponse<IUserNavbarConfiguration>>> {
        return axios.put(`${resource}/navbar`, {navbar_configuration: navbarConfiguration});
    },

    getPageOrderConfigurationByConnectedUSer(page: string): Promise<AxiosResponse<IServerResponse<IPageOrderConfiguration>>> {
        return axios.get(`${resource}/page_order/${page}`);
    },

    postPageOrderConfiguration(page: string, orderConfiguration: IOrderConfiguration): Promise<AxiosResponse<IServerResponse<IPageOrderConfiguration>>> {
        return axios.post(`${resource}/page_order`, {page: page, order_configuration: orderConfiguration});
    },

    updatePageOrderConfiguration(page: string, orderConfiguration: IOrderConfiguration): Promise<AxiosResponse<IServerResponse<IPageOrderConfiguration>>> {
        return axios.put(`${resource}/page_order/${page}`, {order_configuration: orderConfiguration});
    },
}