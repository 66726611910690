<template>
    <div id="dialog-profile-component">
        <v-dialog v-model="dialog_profile" max-width="500px">
            <v-card max-height="70%">
                <v-card-actions>
                    <v-icon @click="dialog_profile = false">mdi-close</v-icon>
                </v-card-actions>

                <v-card-text>
                    <div class=" mb-4">
                        <v-icon class="edit-profile-icon pointer" color="secondary"
                                @click="navigateTo('profile'); dialog_profile = false;"
                                data-cy="btn-edit-profile"
                        >
                            mdi-pencil
                        </v-icon>
                        <br>

                        <div class="d-flex justify-center font-26">
                            <profile-picture-component/>
                        </div>

                        <div class="my-2 text-center">
                            <span class="font-weight-bold font-16">{{ user.first_name }} {{ user.last_name }}</span>
                            <br>
                            <span>{{ user.email }}</span>
                        </div>

                        <p class="font-color-light mb-1 mt-4 justify-start">
                            {{ $t('dialog_profile_component.connect_to') }}
                        </p>

                        <v-list-item class="pa-2 container-current-space">
                            <div class="background-current-space"/>
                            <v-list-item-icon class="mr-3 my-auto">
                                <v-btn :loading="switch_account_loading" class="unsolicited-btn mr-2 btn-white-loader"
                                       color="secondary" elevation="0" fab x-small
                                >
                                    <v-icon class="color-white-fix">mdi-tablet-cellphone</v-icon>
                                </v-btn>
                            </v-list-item-icon>

                            <v-list-item-title class="text-start">
                                <span class="font-14 secondary--text font-weight-bold">
                                    {{ user.client_space_name }}
                                </span><br>

                                <span class="font-12 secondary--text">
                                    {{ $t('dialog_profile_component.role') }}
                                    <strong>{{ user.role_client ? user.role_client.name : '' }}</strong>
                                </span>
                            </v-list-item-title>

                            <v-list-item-action class="my-auto pr-4 justify-center w-30">
                                <v-img :src="$store.getters['display_option/logo']" max-width="100px" max-height="40px"
                                       contain
                                />
                            </v-list-item-action>
                        </v-list-item>
                    </div>

                    <v-progress-linear :color="loading ? 'primary' : 'grey'" :indeterminate="loading" height="1px"/>

                    <div v-if="clients.length > 0">
                        <p class="font-color-light mt-5 mb-1 font-14">
                            {{ $t('dialog_profile_component.other_space_available') }}
                        </p>
                        <div class="mb-2">
                            <v-list-item v-for="client in clients" :key="client.id_client"
                                         class="font-weight-bold font-14 my-0 pointer pa-1"
                                         @click="switchClient(client.id_client)"
                            >
                                <v-list-item-icon class="mr-4 my-auto">
                                    <v-btn :loading="switch_account_loading"
                                           class="unsolicited-btn mr-2 btn-white-loader" elevation="0" fab
                                           x-small
                                           :color="$vuetify.theme.themes.light.default_text"
                                    >
                                        <v-icon class="color-white-fix">mdi-tablet-cellphone</v-icon>
                                    </v-btn>
                                </v-list-item-icon>

                                <v-list-item-title>
                                    <span class="font-14">{{ client.client_space_name }}</span>
                                    <br>
                                    <span class="font-12 font-weight-light">
                                        {{ $t('dialog_profile_component.role') }}
                                    </span>
                                    <span class="font-12">{{ client.role_client.name }}</span>
                                </v-list-item-title>

                                <v-list-item-action class="my-auto pr-4 justify-center w-30">
                                    <v-img v-if="client.url_logo" :src="client.url_logo" max-width="100px"
                                           max-height="40px" contain
                                    />
                                </v-list-item-action>
                            </v-list-item>
                        </div>
                        <v-divider/>
                    </div>

                    <div v-if="users_invitation.length > 0" class="mb-2">
                        <p class="font-color-light font-14 mt-4 mb-1">
                            {{ $t('dialog_profile_component.invitation_waiting') }}
                        </p>

                        <v-list-item
                            v-for="invitation in users_invitation" :key="invitation.token"
                            class="font-weight-bold font-14 mt-0 mb-2 pa-1"
                        >
                            <v-list-item-icon class="mr-2 my-auto">
                                <v-btn :loading="manage_invitation_loading"
                                       :color="$vuetify.theme.themes.light.default_text_light"
                                       class="unsolicited-btn mr-2 btn-white-loader"
                                       elevation="0" fab small
                                >
                                    <v-icon class="color-white-fix">mdi-tablet-cellphone</v-icon>
                                </v-btn>
                            </v-list-item-icon>

                            <v-list-item-title>
                                <v-row class="ma-0">
                                    <div class="overflow-hidden invitation-details">
                                        <span class="font-14 font-color-light">{{
                                                invitation.client.client_space_name
                                            }}</span><br>
                                        <span class="font-12 font-color-light font-weight-light">
                                            {{ $t('dialog_profile_component.role') }}
                                        </span>
                                        <span class="font-12 font-color-light">{{ invitation.role_client.name }}</span>
                                    </div>

                                    <v-row class="ma-0 justify-end">
                                        <div class="pointer text-center px-1 background-white z-index-1"
                                             @click="acceptInvitation(invitation.token)"
                                        >
                                            <v-icon color="success">mdi-check-circle-outline</v-icon>
                                            <br>
                                            <span class="font-color-light font-weight-light font-12">
                                                {{ $t('global.validate') }}
                                            </span>
                                        </div>

                                        <div
                                            class="pointer text-center px-4 background-white z-index-1"
                                            @click="declineInvitation(invitation.token)"
                                        >
                                            <v-icon color="error">mdi-close-circle-outline</v-icon>
                                            <br>
                                            <span class="font-color-light font-weight-light font-12">
                                                {{ $t('global.decline') }}
                                            </span>
                                        </div>
                                    </v-row>
                                </v-row>
                            </v-list-item-title>
                        </v-list-item>
                        <v-divider/>
                    </div>

                    <v-btn block class="my-4 text--black" elevation="0" large
                           @click="dialog_profile = false; logout()"
                    >
                        <span class="font-weight-bold">
                            {{ $t('dialog_profile_component.logout') }}
                        </span>
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import UserRepository from "@/repositories/UserRepository";
import UserInvitationRepository from "@/repositories/UserInvitationRepository";
import ProfilePictureComponent from "@/components/Profile/ProfilePictureComponent";

export default {
    name: 'DialogProfileComponent',
    components: {ProfilePictureComponent},
    data() {
        return {
            dialog_profile: false,
            users_invitation: [],
            loading: false,
            switch_account_loading: false,
            manage_invitation_loading: false,
            current_space: ''
        }
    },
    computed: {
        clients: {
            get: function () {
                return this.$store.getters['clients/clients'];
            },
            set: function () {
                this.$store.dispatch('clients/getClientsOfUser');
            },
        },
        user: {
            get: function () {
                return this.$store.getters['user_connected/user_connected'];
            },
            set: function () {
                this.$store.dispatch('user_connected/getAuthenticateUser');
            },
        }
    },
    methods: {
        switchClient(idClient) {
            this.switch_account_loading = true;
            UserRepository.getSwitchClientAsUserClient(this.user.id_user, idClient).then((success) => {
                this.$store.dispatch('asset_types/resetAssetTypeWithAsset');
                this.$auth.token(null, success.data.data);

                this.$auth.fetch({}).then((result) => {
                    this.$store.dispatch('user_connected/setAuthenticateUser', result.data.data);
                    this.setAllInformationAfterConnect(result.data.data.id_professional, result.data.data);
                });
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
                this.switch_account_loading = false;
                this.dialog_profile = false;
            });
        },

        getUserInvitation() {
            this.loading = true;
            this.users_invitation = [];
            UserRepository.getUserInvitation(this.user.id_user).then((success) => {
                let invitations = success.data.data;
                if (invitations.length > 0) {
                    invitations.forEach(invitation => {
                        if (invitation.state === 0) {
                            this.users_invitation.push(invitation);
                        }
                    })
                }
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
                this.loading = false;
            });
        },

        acceptInvitation(token) {
            this.manage_invitation_loading = true;
            UserInvitationRepository.putAcceptInvitation(token).then(() => {
                this.showSnackbar('success', this.$t('dialog_profile_component.invitation_accept'));
                this.getUserInvitation();
                this.getClientsOfUser();
            }).catch((error) => {
                if (error.response.status === 404) {
                    this.showSnackbar('warning', this.$t(`error_display.user_invitation_not_found`))
                    this.getUserInvitation();
                    this.getClientsOfUser();
                } else {
                    this.manageError(error);
                }
            }).finally(() => {
                this.manage_invitation_loading = false;
            });
        },

        declineInvitation(token) {
            this.manage_invitation_loading = true;
            UserInvitationRepository.putDeclineInvitation(token).then(() => {
                this.showSnackbar('success', this.$t('dialog_profile_component.invitation_decline'));
                this.getUserInvitation();
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
                this.manage_invitation_loading = false;
            });
        },
    },
    watch: {
        dialog_profile() {
            if (this.dialog_profile) {
                this.getUserInvitation();
                this.$store.dispatch('clients/getClientsOfUser', this.$store.getters['user_connected/user_connected'].id_user);
            }
        }
    }
}
</script>

<style>
.background-current-space {
    background: var(--v-secondary-base) !important;
    opacity: 0.2 !important;
    height: 100%;
    width: 100%;
    bottom: 0;
    left: 0;
    position: absolute;
}

.container-current-space {
    border: 1px solid var(--v-secondary-base);
    border-radius: 3px;
    overflow: hidden;
}

.edit-profile-icon {
    position: absolute !important;
    right: 30px;
    top: 60px;
}

.invitation-details {
    position: absolute;
}
</style>