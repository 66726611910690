import UserRepository from "@/repositories/UserRepository";

const state = () => ({
    user_connected: {}
});

const mutations = {
    setInformationAuthenticateUser(state, userConnected: IUserAuthenticate): void {
        state.user_connected = userConnected;
    },

    setSomePropsAuthenticateUser(state, userConnected: IUserAuthenticate): void {
        state.user_connected.last_name = userConnected.last_name;
        state.user_connected.first_name = userConnected.first_name;
        state.user_connected.email = userConnected.email;
        state.user_connected.language = userConnected.language;
    }
};

const actions = {
    async getAuthenticateUser({commit}): Promise<void> {
        await UserRepository.getAuthenticateUser().then((success) => {
            commit('setInformationAuthenticateUser', success.data.data);
        });
    },

    async setAuthenticateUser({commit}, authenticateUser: IUserAuthenticate): Promise<void> {
        commit('setInformationAuthenticateUser', authenticateUser);
    },

    async setSomePropsAuthenticateUser({commit}, authenticateUser: IUserAuthenticate): Promise<void> {
        commit('setSomePropsAuthenticateUser', authenticateUser);
    }
};

const getters = {
    user_connected: (state): void => state.user_connected,
    getPermissionByDesignation: (state) => (designation) => state.user_connected.permission_clients.find(permission => permission.designation_module === designation)
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
