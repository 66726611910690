import axios from '../plugins/axios';
import {AxiosResponse} from "axios";

const resource = 'api/v1/global';

export default {
    getScrapedArticles(): Promise<AxiosResponse<IServerResponse<IArticle[]>>> {
        return axios.get(`${resource}/scraped_articles`);
    },

    sendOpinion(data): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.post(`${resource}/opinion`, data);
    },

    sendBug(data): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.post(`${resource}/bug`, data);
    },

    sendAssistanceRequired(data): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.post(`${resource}/assistance_required`, data);
    }
}