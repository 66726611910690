import axios from '../plugins/axios';
import {AxiosResponse} from "axios";

const resource = 'api/v1/order/cockpit';

export default {
    getOrderCockpitListByState(state): Promise<AxiosResponse<IServerResponse<IOrderCockpit[]>>> {
        return axios.get(`${resource}?state=${state}`);
    },

    getOrderCockpitListById(id: number): Promise<AxiosResponse<IServerResponse<IOrderCockpit>>> {
        return axios.get(`${resource}/${id}`);
    },

    getOrderCockpitProductsByIdAsset(idAsset: number): Promise<AxiosResponse<IServerResponse<IProduct[]>>> {
        return axios.get(`${resource}/products?id_asset=${idAsset}`);
    },

    validateOrderCockpit(id: number, order): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.post(`${resource}/${id}/validate`, order, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },

    deleteOrderCockpitById(id: number): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.delete(`${resource}/${id}`);
    }
}