const state = () => ({
    show_global_loader: true,
    id_professional: null,
});

const mutations = {
    setGlobalLoader(state, globalLoader: boolean): void {
        state.show_global_loader = globalLoader;
    },

    setIdProfessional(state, idProfessional: number): void {
        state.id_professional = idProfessional;
    },
};

const actions = {
    async setGlobalLoader({commit}, globalLoader: boolean): Promise<void> {
        await commit('setGlobalLoader', globalLoader);
    },

    async setIdProfessional({commit}, idProfessional: number): Promise<void> {
        await commit('setIdProfessional', idProfessional);
    },
};

const getters = {
    show_global_loader: (state) => state.show_global_loader,
    id_professional: (state) => state.id_professional,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
