import axios from "@/plugins/axios";
import {AxiosResponse} from "axios";

const resource = 'api/v1/asset';

export default {
    getAssetByAssetTypeAndClient(idClient: number, idAssetType: number): Promise<AxiosResponse<IServerResponse<IAssetCompleteInformation[]>>> {
        return axios.get(`${resource}/asset_type/${idAssetType}`);
    },

    postUploadPicture(idAsset: number, files): Promise<AxiosResponse<IServerResponse<any>>> {
        return axios.post(`${resource}/${idAsset}/image`, files);
    },

    putEditAsset(idAsset: number, asset, domicile): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.put(resource + '/' + idAsset, {'asset': asset, 'domicile': domicile});
    },

    deletePicture(idAsset: number, name): Promise<AxiosResponse<IServerResponse<any>>> {
        return axios.delete(`${resource}/${idAsset}/image?name=${name}`);
    },
}
