import axios from "@/plugins/axios";
import {AxiosResponse} from "axios";

const resource = 'api/v1/notification';

export default {
    getNotificationByIdUserAndIdClient(idClient: number): Promise<AxiosResponse<IServerResponse<INotification[]>>> {
        return axios.get(`${resource}/user/client/${idClient}`);
    },

    updateIsSeeNotifications(idNotifications: number[]): Promise<AxiosResponse<IServerResponse<void>>> {
        return axios.put(`${resource}/is_see`, {id_notifications: idNotifications});
    },

    updateIsReadNotifications(idNotifications: number[]): Promise<AxiosResponse<IServerResponse<void>>> {
        return axios.put(`${resource}/is_read`, {id_notifications: idNotifications});
    },

    deleteNotificationById(idNotification: number): Promise<AxiosResponse<void>> {
        return axios.delete(`${resource}/${idNotification}`);
    }
}