<template>
    <div v-if="$auth.user() && is_mobile && $store.getters['manage_navbar/show_bottom_navbar']" id="bottom-navbar">
        <v-bottom-navigation app class="bottom-navigation-custom" color="primary" height="83" data-cy="bottom-navbar">
            <template
                v-for="(configuration,index) in $store.getters['user_navbar_configuration/navbar_configurations'].slice(0,4)"
            >
                <v-btn v-if="configuration.displayed && userHasAccess(configuration.designation, role_no_access)"
                       :key="configuration.page_link" :to="{ path: '/' + configuration.page_link }"
                       link
                       class="btn-bottom-module"
                >
                        <span v-if="configuration.page_link === 'equipment'" class="mt-2 font-weight-bold font-10">
                            {{ page_name_equipment }}
                        </span>
                    <span v-else class="mt-2 font-weight-bold font-10">
                            {{ $t('configuration_navbar.' + configuration.page_name) }}
                        </span>

                    <div class="position-relative">
                        <v-badge
                            :content="calculateNbNotificationsByModule(configuration.page_link)"
                            :value="calculateNbNotificationsByModule(configuration.page_link)"
                            class="badge-notification-lower-size"
                            color="error"
                            overlap
                        >
                            <v-icon class="hidden-icon">mdi-devices</v-icon>
                        </v-badge>

                        <v-icon class="bottom-navbar-icon" :color="index === selected_module ? 'primary' : ''">
                            {{ configuration.icon }}
                        </v-icon>
                    </div>
                </v-btn>
            </template>
        </v-bottom-navigation>
    </div>
</template>

<script>

export default {
    name: "BottomNavbarComponent",
    created() {
        this.$watch(() => this.$route, () => {
            if (this.$auth.user() !== null) {
                this.selected_module = -1;
                this.$store.getters['user_navbar_configuration/navbar_configurations'].slice(0, 4).forEach((configuration, index) => {
                    if (this.$router.currentRoute.name.includes(configuration.page_name)) {
                        this.selected_module = index;
                    }
                });
            }
        });
    },
    data() {
        return {
            selected_module: '',
        }
    }
}
</script>

<style>
.hidden-icon {
    opacity: 0;
}

.btn-bottom-module .v-icon {
    color: var(--v-black-lighten5) !important;
}

.bottom-navbar-icon {
    position: absolute !important;
    right: 0px;
}
</style>