import axios from '../plugins/axios';
import {AxiosResponse} from "axios";

const resource = 'api/v1/device';

export default {
    getDeviceDtdByIdDevice(id: number, filter_date): Promise<AxiosResponse<IServerResponse<IDeviceTraitedData[]>>> {
        return axios.get(`${resource}/${id}/device_traited_data${filter_date}`);
    },

    getDeviceDataConsumptionByIdDevice(id: number, data): Promise<AxiosResponse<IServerResponse<string[]>>> {
        return axios.post(`${resource}/${id}/device_traited_data/consumption`, data);
    },

    getDeviceInstallationValidationWithUser(deviceNumber: string): Promise<AxiosResponse<IServerResponse<any>>> {
        return axios.get(`${resource}/${deviceNumber}/is_installable/user`);
    },

    getAllAlertByIdDevice(id: number): Promise<AxiosResponse<IServerResponse<IAlert[]>>> {
        return axios.get(`${resource}/${id}/alerts`);
    },

    getAlertsEventsByIdDevice(id: number): Promise<AxiosResponse<IServerResponse<IAlertEvent[]>>> {
        return axios.get(`${resource}/${id}/alerts/events`);
    },

    getAllMetricByIdDevice(id: number): Promise<AxiosResponse<IServerResponse<IMetric[]>>> {
        return axios.get(`${resource}/${id}/metrics`);
    },
}